/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from "react"
import Media from "react-media"
import { graphql } from "gatsby"
import Layout from "../../templates/pageLayout"
import ServiceBtn from "../../components/serviceBtn"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import * as servicesCategoryStyles from "../../components/servicesCategory.module.css"

export default class Automotive extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayLongerCopy: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayLongerCopy: true })
  }

  render() {
    const {
      breadcrumb: { crumbs },
    } = this.props.pageContext

    return (
      <Layout title="Automotive Services">
        <div className="container">
          <div className={servicesCategoryStyles.servicesContainer}>
            <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
            <h2>
              <span className={servicesCategoryStyles.serviceCategory}>
                AUTOMOTIVE{" "}
              </span>
              SERVICES
            </h2>
            <p>
              Our team consists of experts and professionals with over 25 years
              of experience in the automotive customisation industry. We pride
              ourselves on our approach and using quality materials, being
              official applicators/fitters of LLumar, X-coat and KubeBond.
              <br />
              <br />
              To find out more about our services.{" "}
              <span style={{ color: "#FF6633" }}>
                Please select a category below.
              </span>
            </p>
            <ServicesContent data={this.props.data} />
          </div>
        </div>
      </Layout>
    )
  }
}

class ServicesContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSubCategory: "View All",
      services: [],
    }
  }

  componentDidMount() {
    const { data } = this.props
    const subCategories = [
      "View All",
      "Care",
      "Protection",
      "Customisation",
      "Classic Cars",
    ]
    const subCategoriesList = document.querySelector("#subCategoryFilter")

    const underline = document.createElement("div")
    underline.classList.add("filterItem__activeUnderline")

    {
      for (let i = 0; i < subCategories.length; i++) {
        const div = document.createElement("div")
        subCategoriesList.appendChild(div)

        div.classList.add("filterItem")
        div.innerHTML = subCategories[i]
        div.setAttribute("id", subCategories[i])

        if (subCategories[i] === this.state.selectedSubCategory) {
          div.classList.add("filterItem__active")
          div.appendChild(underline)
        }

        div.addEventListener("click", () => {
          this.setState({
            selectedSubCategory: subCategories[i],
          })
        })
      }
    }

    this.setState({ services: [] })

    data.allMdx.edges.map(({ node }, index) => {
      this.setState(state => {
        const services = state.services.push(
          <div key={node.frontmatter.order}>
            <ServiceBtn
              link={node.fields.slug + "/"}
              title={node.frontmatter.title}
              fileName={node.frontmatter.imgFile + ".jpg"}
              id={node.frontmatter.title}
              key={index}
              animateIn="animate__fadeIn"
            />
          </div>
        )
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let selectedSubCategory = this.state.selectedSubCategory
    const { data } = this.props

    const underline = document.createElement("div")
    underline.classList.add("filterItem__activeUnderline")

    if (selectedSubCategory) {
      if (selectedSubCategory != prevState.selectedSubCategory) {
        this.setState({
          services: [],
        })

        let newCategory = document.getElementById(selectedSubCategory)
        newCategory.appendChild(underline)
        newCategory.classList.add("filterItem__active")

        let prevCategory = document.getElementById(
          prevState.selectedSubCategory
        )
        prevCategory.removeChild(prevCategory.childNodes[1])
        prevCategory.classList.remove("filterItem__active")

        data.allMdx.edges.map(({ node }, index) => {
          if (selectedSubCategory === "View All") {
            this.setState(state => {
              const services = state.services.push(
                <div key={node.frontmatter.order}>
                  <ServiceBtn
                    link={node.fields.slug + "/"}
                    title={node.frontmatter.title}
                    fileName={node.frontmatter.imgFile + ".jpg"}
                    id={node.frontmatter.title}
                    key={index}
                  />
                </div>
              )
            })
          } else if (
            node.frontmatter.subCategory === this.state.selectedSubCategory
          ) {
            this.setState(state => {
              const services = state.services.push(
                <div key={node.frontmatter.order}>
                  <ServiceBtn
                    link={node.fields.slug + "/"}
                    title={node.frontmatter.title}
                    fileName={node.frontmatter.imgFile + ".jpg"}
                    id={node.frontmatter.title}
                    key={index}
                  />
                </div>
              )
            })
          }
        })
      }
    }
  }

  render() {
    let { services } = this.state

    return (
      <div>
        <div>
          <div id="subCategoryFilter"></div>
        </div>
        <div className={servicesCategoryStyles.servicesGrid} id="servicesGrid">
          {services.sort((a, b) => (a.key > b.key ? 1 : -1))}
        </div>
      </div>
    )
  }
}

export const query = graphql`
  query AutomotiveServicesQuery {
    allMdx(
      filter: {
        frontmatter: {
          type: { eq: "Service" }
          category: { in: ["Automotive", "Car"] }
        }
      }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            type
            category
            subCategory
            title
            imgFile
            order
          }
        }
      }
    }
  }
`
